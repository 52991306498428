<div id="portfolio"
  class="statsSection no-gutters">
  <div class="slides-container">
    <div class="container-fluid">
      <div class="row">
        <div class="heading"> 
        </div>
        <div class="itemsContainer">
          <ul class="row items">
            <li (click)="setSelectedItem(items)"
              class="{{ items.class }} col-12 col-sm-12 col-md-12 col-lg-4"
              data-toggle="modal"
              data-target="#myModal"
              *ngFor="let items of project">
              <div class="item">
                <img src="{{ items.cover }}">
                <div class="imageOverlay">
                  <div class="video-background-content container h-100">
                    <div class="d-flex h-100 text-center align-items-center">
                      <div class="w-100 text-white image-text">
                        <p class="lead mb-0">{{ items.lead }}</p>
                        <p class="sub-lead">{{ items.byline }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div *ngIf="showDialog"
            class="modal"
            id="myModal"
            role="dialog">
            <div class="modal-body the-modal">
              <div class="modal-header">
                <button type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  (click)="showDialog = false;"
                  style="color: white;">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="container-fluid h-100">
                <div class="row justify-content-center align-self-center">
                  <div class="col-lg-8 col-md-12 ml-auto embed-responsive embed-responsive-16by9 z-depth-1-half">
                    <iframe [src]="selectedItem?.video | safe"
                      allowfullscreen></iframe>
                  </div>
                    <div class="col-lg-4 col-md-12 ml-auto">
                      <div class="project-meta">
                        <p class="project-title mb-0">{{ selectedItem.lead }}</p>
                        <p class="project-byline">{{ selectedItem.byline }}</p>
                        <div class="project-description">
                          <p>{{ selectedItem.dek }}</p>
                          <p>{{ selectedItem.dekArtist }}</p>
                          <div class="credits">
                            <div class="project-credits">
                              <p *ngFor="let cred of selectedItem.projectCredits">{{ cred }}</p>
                            </div>
                            <div class="livesession-credits">
                              <p *ngFor="let lsCred of selectedItem.liveSessionCredits">{{ lsCred }}</p>
                            </div>
                            <div class="title-credits">
                              <p *ngFor="let tCred of selectedItem.titleCredits">{{ tCred }}</p>
                            </div>
                            <div class="production-credits">
                              <p *ngFor="let prodCred of selectedItem.productionCredits">{{ prodCred }}</p>
                            </div>
                            <div class="artist-credits">
                              <p>{{ selectedItem.artistCredits }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
