<div id="team"
    class="skillsSection">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <hr class="line"
                    width="40%">
                <h2>TEAM</h2>
                <ul class="row titem">
                    <li onclick=""
                        class="col-6 col-sm-12 col-md-6 col-lg-6"
                        *ngFor="let items of people">
                        <div class="item">
                            <img src="{{ items.image }}">
                            <div class="icons">
                                <!-- <a><i class="fab fa-facebook"></i></a>
                                <a><i class="fab fa-instagram"></i></a> -->
                                <span class="teamName">{{ items.name }}</span>
                            </div>
                            <div class="imageOverlay"></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>