<div id="feature" class="cover" data-toggle="modal" data-target="#myModal">
    <!-- <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog the-modal">
            <div class="modal-body">
                <div class="modal-header">
                    <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        style="color: white;">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/PAY4_tBq7rw?controls=0&modestbranding=1" id="video" allowscriptaccess="always"></iframe>
                </div>
            </div>
        </div> -->
</div>