<section id="about" class="about_profile">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-justify">
                <!-- <img class="about_logo rounded-circle z-depth-2" src="https://res.cloudinary.com/kaushalshah1307/image/upload/v1599044103/Friends%27%20Republic/Assets/fr_logo_Original.png"> -->
                <p class="about_para">
                    In 2016, school buddies, <a class="namesInsta" href="https://www.instagram.com/chinmay_fr/" target="_blank">Chinmay</a> and <a class="namesInsta" href="https://www.instagram.com/hetan_fr/" target="_blank">Hetan</a> 
                    teamed up to found Friends’ Republic - a Mumbai based film production company that creates music videos, 
                    documentaries, branded content and all that filmmaking goodness. The idea is to produce emotionally resonant work in a variety of formats.
                </p>
                <p class="about_para" *ngFor="let about of aboutus"> 
                    {{ about.para2 }}
                </p>
                <p class="about_para" *ngFor="let about of aboutus"> 
                    {{ about.para3 }}
                </p>
            </div>
        </div>
    </div>
</section>