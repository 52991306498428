<div id="contact" class="contactSection">
    <div class="col-md-12 text-justify">
        <div class="row">
            <div class="col socials">
                <p><a class="socialLinks" href="mailTo:friends.republic@yahoo.com">Email</a></p>
                <p style="display: block;">
                    <span style="display: block;"><a class="socialLinks" href="https://www.instagram.com/friendsrepublic/" target="_blank">Instagram</a></span>
                    <span style="display: block;"><a class="socialLinks" href="https://vimeo.com/channels/friendsrepublic" target="_blank">Vimeo</a></span>
                    <span style="display: block;"><a class="socialLinks" href="https://www.youtube.com/user/FriendsRepublic" target="_blank">Youtube</a></span>
                </p>
            </div>
            <div class="col logo">
                <img class="about_logo rounded-circle z-depth-2" src="https://res.cloudinary.com/kaushalshah1307/image/upload/v1599044103/Friends%27%20Republic/Assets/fr_logo_Original.png">
            </div>
        </div>
    </div>
</div>