<nav id="navigation" class="navbar navbar-expand-lg fixed-top scrolling-navbar">
    <a class="navbar-brand" href="#">
        <img id="nav-brand" class="nav-logo" src="https://res.cloudinary.com/kaushalshah1307/image/upload/v1599044103/Friends%27%20Republic/Assets/fr_logo_Original.png" alt="Friends Republic">
    </a>
    <button class="navbar-toggler collapsed" (click)="isCollapsed = !isCollapsed" type="button" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse no-transition" [attr.aria-expanded]="!isCollapsed" [ngClass]="{collapse: isCollapsed}" id="navbarNav">
        <ul class="navbar-nav" (click)="isCollapsed = !isCollapsed">
            <li class="nav-item">
                <a class="nav-link" href="#portfolio">WORK</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#feature">FEATURE</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#about">CONNECT</a>
            </li>
        </ul>
    </div>
</nav>