<div>
    <!-- nav component -->
    <app-nav></app-nav>

    <!-- video dek component -->
    <app-dek></app-dek>

    <!-- work component -->
    <app-portfolio></app-portfolio>

    <!-- feature component -->
    <app-feature></app-feature>

    <!-- about component -->
    <app-about></app-about>

    <!-- team component -->
    <!-- <app-team></app-team> -->

    <!-- contact component -->
    <app-contact></app-contact>
    
    <!-- footer component -->
    <app-footer></app-footer>
</div>

<router-outlet></router-outlet>